//SliderSlick

//Appel autres fichiers
/*var $ = require('jquery');
window.$ = $;

require('./slick.min.js');

$.noConflict();*/

$('.main_slider.demarches').slick({
    centerMode: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 300,
    centerPadding: '60px',
    slidesToShow: 5,
    slidesToScroll: 5,
    pauseOnHover: true,
    pauseOnFocus: true,
    dots: false,
    rows: 0,
    prevArrow: $(".prev-arrow.demarches"),
    nextArrow: $(".next-arrow.demarches"),
    asNavFor: '.slider_dots',
    lazyLoad: 'ondemand', 
    responsive: [{
            breakpoint: 1400,
            settings: {
                slidesToShow: 3,
                infinite: true,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                infinite: true,
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 1,
                infinite: true,
            }
        }, 
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                centerMode: false,
            }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
    ]
})
.on('setPosition', function (event, slick) {
    slick.$slides.css('height', slick.$slideTrack.height() + 'px');
});
$('.slider_dots.demarches').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: '.main_slider',
    arrows: false,
    dots: false,
    centerMode: true,
    infinite: true,
    focusOnSelect: true,
    centerPadding: '63px',
});
